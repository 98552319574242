import React from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import SEO from "../components/seo";
import ProductCard from "../components/ProductCard";

export const pageQuery = graphql`
  query AllProducts {
    allPrismicProduct {
      nodes {
        uid
      }
    }
    prismicShop {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_image {
          url
        }
        products {
          product {
            document {
              ... on PrismicProduct {
                type
                uid
                data {
                  title {
                    text
                  }
                  reference
                  gutter
                  image_featured {
                    ...sharpThumbnail
                  }
                  # images {
                  #   image {
                  #     ...sharp
                  #   }
                  # }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Shop = ({ path, pageContext, data }) => {
  const { template } = pageContext;
  const { meta_title, meta_description, meta_image, products } =
    data.prismicShop.data;

  const length = products.length;
  // const length = 1;
  return (
    <div className='page-template page-shop'>
      <SEO
        pageTitle={meta_title.text}
        pageDescription={meta_description.text}
        pageImage={meta_image.url}
        template={template}
        page={true}
        documentClass=''
      />
      <div className='px-md'>
        <div className=' grid-center min-h-screen'>
          <div className=' products flex flex-wrap justify-center content-center items-baseline '>
            {products.map(({ product }, i) => (
              <ProductCard input={product} key={i} total={length} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPreview(Shop);
